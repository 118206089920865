import { create } from 'lodash';
import { combineReducers } from 'redux';
import {
  DEFAULT_STATE,
  DEFAULT_STATE_FF_EF,
  DEFAULT_STATE_FF_ET
} from '../../utils/Constants';
import Auth from './Auth';
import Theme from './Theme';

const createReducer = (reducerName, defaultStateParam) => {
  const defaultState = defaultStateParam || DEFAULT_STATE;
  return (state = defaultState, action) => {
    switch (action.type) {
      case `${reducerName}_PENDING`:
      case `${reducerName}_FULFILLED`:
      case `${reducerName}_REJECTED`:
        return Object.assign({}, action.payload);
      default:
        return state;
    }
  };
};
const createStaticReducer = (reducerName, defaultStateParam) => {
  const defaultState = defaultStateParam || DEFAULT_STATE;
  return (state = defaultState, action) => {
    switch (action.type) {
      case `${reducerName}`:
        return Object.assign({}, action.payload);
      default:
        return state;
    }
  };
};

// const createStaticReducer = (reducerName, defaultStateParam) => {
//   const defaultState = defaultStateParam || DEFAULT_STATE;
//   return (state = defaultState, action) => {
//     switch (action.type) {
//       case `${reducerName}`:
//         return Object.assign({}, action.payload);
//       default:
//         return state;
//     }
//   };
// };

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  login: createReducer('LOGIN', DEFAULT_STATE_FF_EF),
  forgotPassword: createReducer('FORGOT_PASSWORD', DEFAULT_STATE_FF_EF),
  changePassword: createReducer('CHANGE_PASSWORD', DEFAULT_STATE_FF_EF),
  logout: createReducer('LOGOUT', { logout: false }),
  profileInfo: createReducer('PROFILE_INFO', DEFAULT_STATE_FF_EF),
  changeUserImage: createReducer('CHANGE_USER_IMAGE', DEFAULT_STATE_FF_EF),
  changeUserPassword: createReducer(
    'CHANGE_USER_PASSWORD',
    DEFAULT_STATE_FF_EF
  ),
  users: createReducer('USERS', DEFAULT_STATE_FF_EF),
  userAdd: createReducer('USER_ADD', DEFAULT_STATE_FF_EF),
  userById: createReducer('USER_BY_ID', DEFAULT_STATE_FF_EF),
  userDelete: createReducer('USER_DELETE', DEFAULT_STATE_FF_EF),
  userInfo: createReducer('USER_INFO', DEFAULT_STATE_FF_EF),
  userInfoProfilePicture: createReducer(
    'USER_INFO_PROFILE_PICTURE',
    DEFAULT_STATE_FF_EF
  ),
  userInfoGroupChat: createReducer('USER_INFO_GROUP_CHAT', DEFAULT_STATE_FF_EF),
  userInfoChannelChat: createReducer(
    'USER_INFO_CHANNEL_CHAT',
    DEFAULT_STATE_FF_EF
  ),
  userBlock: createReducer('USER_BLOCK', DEFAULT_STATE_FF_EF),
  chatUserDelete:createReducer('CHAT_USER_DELETE', DEFAULT_STATE_FF_EF),
  userLoggedDevices: createReducer('USER_LOGGED_DEVICES', DEFAULT_STATE_FF_EF),
  userCallOverview: createReducer('USER_CALLS_OVERVIEW', DEFAULT_STATE_FF_EF),
  userFeedbackOverview: createReducer(
    'USER_FEEDBACK_OVERVIEW',
    DEFAULT_STATE_FF_EF
  ),
  userPlans: createReducer('USER_PLANS', DEFAULT_STATE_FF_EF),
  
  userPaymentOverview: createReducer(
    'USER_PAYMENTS_OVERVIEW',
    DEFAULT_STATE_FF_EF
  ),
  userOverview: createReducer('USER_OVERVIEW', DEFAULT_STATE_FF_EF),

  //GroupInfo
  groupInfo: createReducer('GROUP_INFO', DEFAULT_STATE_FF_EF),
  groupMemberInfo: createReducer('GROUP_MEMBER_INFO', DEFAULT_STATE_FF_EF),
  groupAdminInfo: createReducer('GROUP_ADMIN_INFO', DEFAULT_STATE_FF_EF),

  //getAdminUserInfo
  adminUserInfo: createReducer('ADMIN_USER_INFO', DEFAULT_STATE_FF_EF),
  adminUserAdd: createReducer('ADMIN_USER_ADD', DEFAULT_STATE_FF_EF),

  //ChannelInfo
  channelInfo: createReducer('CHANNEL_INFO', DEFAULT_STATE_FF_EF),
  channelMemberInfo: createReducer('CHANNEL_MEMBER_INFO', DEFAULT_STATE_FF_EF),
  channelAdminInfo: createReducer('CHANNEL_ADMIN_INFO', DEFAULT_STATE_FF_EF),
  //Dashboard
  dashboardCountInfo: createReducer(
    'DASHBOARD_COUNT_INFO',
    DEFAULT_STATE_FF_EF
  ),
  dashboardCallStats: createReducer(
    'DASHBOARD_CALL_STATS',
    DEFAULT_STATE_FF_EF
  ),

  dashboardCall: createReducer('DASHBOARD_CALL', DEFAULT_STATE_FF_EF),
  dashboardKycCounts: createReducer(
    'DASHBOARD_KYC_COUNTS',
    DEFAULT_STATE_FF_EF
  ),
  dashboardTransactionList: createReducer(
    'DASHBOARD_TRANSACTION_LIST',
    DEFAULT_STATE_FF_EF
  ),

  permissions: createReducer('PERMISSIONS', DEFAULT_STATE_FF_EF),
  userPermissions: createReducer('USER_PERMISSIONS', DEFAULT_STATE_FF_EF),

  subscriptions: createReducer('SUBSCRIPTIONS', DEFAULT_STATE_FF_EF),
  subscriptionAdd: createReducer('SUBSCRIPTION_ADD', DEFAULT_STATE_FF_EF),
  subscriptionById: createReducer('SUBSCRIPTION_BY_ID', DEFAULT_STATE_FF_EF),
  subscriptionDelete: createReducer('SUBSCRIPTION_DELETE', DEFAULT_STATE_FF_EF),

  subscriptionForDD: createReducer('GET_SUBSCRIPTIONS_DD', DEFAULT_STATE_FF_EF),
  currencyForDD: createReducer('GET_CURRENCY_DD', DEFAULT_STATE_FF_EF),

  roles: createReducer('ROLES', DEFAULT_STATE_FF_EF),
  roleAdd: createReducer('ROLE_ADD', DEFAULT_STATE_FF_EF),
  roleById: createReducer('ROLE_BY_ID', DEFAULT_STATE_FF_EF),
  roleDelete: createReducer('ROLE_DELETE', DEFAULT_STATE_FF_EF),
  roleForDD: createReducer('GET_ROLES_DD', DEFAULT_STATE_FF_EF),

  sipRates: createReducer('SIP_RATES', DEFAULT_STATE_FF_EF),
  sipRatesAdd: createReducer('SIP_RATES_ADD', DEFAULT_STATE_FF_EF),
  sipRatesById: createReducer('SIP_RATES_BY_ID', DEFAULT_STATE_FF_EF),
  sipRatesDelete: createReducer('SIP_RATES_DELETE', DEFAULT_STATE_FF_EF),
  // SIP Rate Groups
  sipRatesGroups: createReducer('SIP_RATES_GROUPS', DEFAULT_STATE_FF_EF),
  sipRatesGroupsAdd: createReducer('SIP_RATES_GROUPS_ADD', DEFAULT_STATE_FF_EF),
  sipRatesGroupsById: createReducer(
    'SIP_RATES_GROUPS_BY_ID',
    DEFAULT_STATE_FF_EF
  ),
  sipRatesGroupsDelete: createReducer(
    'SIP_RATES_GROUPS_DELETE',
    DEFAULT_STATE_FF_EF
  ),

  transactions:createReducer('TRANSACTION_LIST', DEFAULT_STATE_FF_EF),
  // SIP DID Groups
  sipDidGroups: createReducer('SIP_DID_GROUPS', DEFAULT_STATE_FF_EF),
  sipDidGroupsAdd: createReducer('SIP_DID_GROUPS_ADD', DEFAULT_STATE_FF_EF),
  sipDidGroupsById: createReducer('SIP_DID_GROUPS_BY_ID', DEFAULT_STATE_FF_EF),
  sipDidGroupsDelete: createReducer(
    'SIP_DID_GROUPS_DELETE',
    DEFAULT_STATE_FF_EF
  ),
  didGroupMeta: createReducer('DID_GROUPS_META', DEFAULT_STATE_FF_EF),
  sipDidHistory: createReducer('SIP_DID_HISTORY', DEFAULT_STATE_FF_EF),
  sipDidEntryAudit:createReducer('SIP_DID_ENTRY_AUDIT', DEFAULT_STATE_FF_EF),
  // SIP Rate Groups Users
  sipRateGrpUsers: createReducer('SIP_RATES_GROUP_USERS', DEFAULT_STATE_FF_EF),
  sipRateGrpUsersAdd: createReducer(
    'SIP_RATES_GROUP_USERS_ADD',
    DEFAULT_STATE_FF_EF
  ),
  sipRateGrpUsersById: createReducer(
    'SIP_RATES_GROUP_USERS_BY_ID',
    DEFAULT_STATE_FF_EF
  ),
  sipRateGrpUsersDelete: createReducer(
    'SIP_RATES_GROUP_USERS_DELETE',
    DEFAULT_STATE_FF_EF
  ),

  // SIP DID Groups Users
  sipDidGrpUsers: createReducer('SIP_DID_GROUP_USERS', DEFAULT_STATE_FF_EF),
  sipDidGrpUsersAdd: createReducer(
    'SIP_DID_GROUP_USERS_ADD',
    DEFAULT_STATE_FF_EF
  ),
  sipDidGrpUsersById: createReducer(
    'SIP_DID_GROUP_USERS_BY_ID',
    DEFAULT_STATE_FF_EF
  ),
  sipDidGrpUsersDelete: createReducer(
    'SIP_DID_GROUP_USERS_DELETE',
    DEFAULT_STATE_FF_EF
  ),
  cdrInfo: createReducer('CDR_INFO', DEFAULT_STATE_FF_EF),
  cdrCallInfo: createReducer('CDR_CALL_INFO', DEFAULT_STATE_FF_EF),

  //DID Numbers
  didNumbers: createReducer('DID_NUMBERS', DEFAULT_STATE_FF_EF),
  didNumbersAdd: createReducer('DID_NUMBERS_ADD', DEFAULT_STATE_FF_EF),
  didNumbersId: createReducer('DID_NUMBERS_BY_ID', DEFAULT_STATE_FF_EF),
  didNumbersDelete: createReducer('DID_NUMBERS_DELETE', DEFAULT_STATE_FF_EF),

  //DID Request
  didRequest: createReducer('DID_REQUEST', DEFAULT_STATE_FF_EF),
  didRequestAdd: createReducer('DID_REQUEST_ADD', DEFAULT_STATE_FF_EF),
  didRequestById: createReducer('DID_REQUEST_BY_ID', DEFAULT_STATE_FF_EF),
  didRequestDelete: createReducer('DID_REQUEST_DELETE', DEFAULT_STATE_FF_EF),
  didRequestApprove: createReducer('DID_REQUEST_APPROVE', DEFAULT_STATE_FF_EF),
  didUnassign: createReducer('DID_NUMBER_UNASSIGN', DEFAULT_STATE_FF_EF),
  didBulkDelete:createReducer('DID_GROUPS_DELETE', DEFAULT_STATE_FF_EF),
  //Transaction
  payment: createReducer('PAYMENT', DEFAULT_STATE_FF_EF),
  paymentAdd: createReducer('PAYMENT_ADD', DEFAULT_STATE_FF_EF),
  paymentById: createReducer('PAYMENT_BY_ID', DEFAULT_STATE_FF_EF),
  paymentDelete: createReducer('PAYMENT_DELETE', DEFAULT_STATE_FF_EF),
  verifyPayment:createReducer('VERIFY_PAYMENT_BY_ID', DEFAULT_STATE_FF_EF),

  couponCode: createReducer('COUPON_CODE', DEFAULT_STATE_FF_EF),
  couponCodeAdd: createReducer('COUPON_CODE_ADD', DEFAULT_STATE_FF_EF),
  couponCodeById: createReducer('COUPON_CODE_BY_ID', DEFAULT_STATE_FF_EF),
  couponCodeDelete: createReducer('COUPON_CODE_DELETE', DEFAULT_STATE_FF_EF),
  //Broadcastgroups
  broadcastGroups: createReducer('BROADCAST_GROUPS', DEFAULT_STATE_FF_EF),
  broadcastGroupsAdd: createReducer('BROADCAST_GROUPS_ADD', DEFAULT_STATE_FF_EF),
  broadcastGroupsById: createReducer('BROADCAST_GROUPS_BY_ID', DEFAULT_STATE_FF_EF),
  broadcastGroupsDelete: createReducer('BROADCAST_GROUPS_DELETE', DEFAULT_STATE_FF_EF),
  //KYC Logs
  kycLogs: createReducer('KYC_LOGS', DEFAULT_STATE_FF_EF),

  // Calls Transactions
  callsTransactions: createReducer('CALLS_TRANSACTIONS', DEFAULT_STATE_FF_EF),

  // Calls SIP
  callsSip: createReducer('CALLS_SIP', DEFAULT_STATE_FF_EF),
  // Calls Webrtc
  callsWebrtc: createReducer('CALLS_WEBRTC', DEFAULT_STATE_FF_EF),
  // SIP Plan Table
  sipPlanUser: createReducer('SIP_PLAN_USER', DEFAULT_STATE_FF_EF),

  //SIP operators
  sipOperators: createReducer('SIP_OPERATORS', DEFAULT_STATE_FF_EF),
  sipOperatorsAdd: createReducer('SIP_OPERATORS_ADD', DEFAULT_STATE_FF_EF),
  sipOperatorsById: createReducer('SIP_OPERATORS_BY_ID', DEFAULT_STATE_FF_EF),
  sipOperatorsDelete: createReducer(
    'SIP_OPERATORS_DELETE',
    DEFAULT_STATE_FF_EF
  ),

  //SIP Operator Prefix
  sipOperatorsPrefix: createReducer('SIP_OPERATORS_PREFIX', DEFAULT_STATE_FF_EF),
  sipOperatorsPrefixAdd: createReducer('SIP_OPERATORS_PREFIX_ADD', DEFAULT_STATE_FF_EF),
  sipOperatorsPrefixById: createReducer('SIP_OPERATORS_PREFIX_BY_ID', DEFAULT_STATE_FF_EF),
  sipOperatorsPrefixDelete: createReducer(
    'SIP_OPERATORS_PREFIX_DELETE', DEFAULT_STATE_FF_EF
    ),
  //SIP Servers
  sipPlan: createReducer('SIP_PLAN', DEFAULT_STATE_FF_EF),
  sipPlanAdd: createReducer('SIP_PLAN_ADD', DEFAULT_STATE_FF_EF),
  sipPlanById: createReducer('SIP_PLAN_BY_ID', DEFAULT_STATE_FF_EF),
  sipPlanDelete: createReducer('SIP_PLAN_DELETE', DEFAULT_STATE_FF_EF),

  //SIP Plan
  sipServers: createReducer('SIP_SERVERS', DEFAULT_STATE_FF_EF),
  sipServersAdd: createReducer('SIP_SERVERS_ADD', DEFAULT_STATE_FF_EF),
  sipServersById: createReducer('SIP_SERVERS_BY_ID', DEFAULT_STATE_FF_EF),
  sipServersDelete: createReducer('SIP_SERVERS_DELETE', DEFAULT_STATE_FF_EF),

  //WebRTC Servers
  webrtcServers: createReducer('WEBRTC_SERVERS', DEFAULT_STATE_FF_EF),
  webrtcServersAdd: createReducer('WEBRTC_SERVERS_ADD', DEFAULT_STATE_FF_EF),
  webrtcServersById: createReducer('WEBRTC_SERVERS_BY_ID', DEFAULT_STATE_FF_EF),
  webrtcServersDelete: createReducer(
    'WEBRTC_SERVERS_DELETE',
    DEFAULT_STATE_FF_EF
  ),

  categories: createReducer('CATEGORIES', DEFAULT_STATE_FF_EF),
  categoryAdd: createReducer('CATEGORY_ADD', DEFAULT_STATE_FF_EF),
  categoryById: createReducer('CATEGORY_BY_ID', DEFAULT_STATE_FF_EF),
  categoryDelete: createReducer('CATEGORY_DELETE', DEFAULT_STATE_FF_EF),

  languages: createReducer('LANGUAGES', DEFAULT_STATE_FF_EF),
  languageAdd: createReducer('LANGUAGE_ADD', DEFAULT_STATE_FF_EF),
  languageById: createReducer('LANGUAGE_BY_ID', DEFAULT_STATE_FF_EF),
  languageDelete: createReducer('LANGUAGE_DELETE', DEFAULT_STATE_FF_EF),

  categoriesForDD: createReducer('GET_CATEGORIES_DD', DEFAULT_STATE_FF_EF),
  genresForDD: createReducer('GET_GENRE_DD', DEFAULT_STATE_FF_EF),
  languagesForDD: createReducer('GET_LANGUAGES_DD', DEFAULT_STATE_FF_EF),
  ForDD: createReducer('GET_CASTROLES_DD', DEFAULT_STATE_FF_EF),
  castsForDD: createReducer('GET_CASTS_DD', DEFAULT_STATE_FF_EF),
  countryForDD: createReducer('GET_COUNTRY_DD', DEFAULT_STATE_FF_EF),
  userForDD: createReducer('GET_USER_DD', DEFAULT_STATE_FF_EF),
  didForDD: createReducer('GET_DID_DD', DEFAULT_STATE_FF_EF),
  sipOperatorForDD: createReducer('GET_SIP_OPERATOR_DD', DEFAULT_STATE_FF_EF),
  sipOperatorPrefixForDD: createReducer('GET_SIP_OPERATOR_PREFIX_DD', DEFAULT_STATE_FF_EF),
  sipRateGroupDD: createReducer('GET_SIP_RATE_GROUP_DD', DEFAULT_STATE_FF_EF),
  didGroupDD: createReducer('GET_DID_GROUP_DD', DEFAULT_STATE_FF_EF),

  sipPlanDD: createReducer('GET_SIPPLAN_DD', DEFAULT_STATE_FF_EF), // to get the sip plans
  couponCodesDD: createReducer('GET_COUPONCODES_DD', DEFAULT_STATE_FF_EF), // to get the sip plans

  siteSettings: createReducer('SITE_SETTINGS', DEFAULT_STATE_FF_EF), // to get site settings
  siteSettingAdd: createReducer('SITE_SETTING_ADD', DEFAULT_STATE_FF_EF),

  //Webrtc-callList
  callList: createReducer('CALL_LIST', DEFAULT_STATE_FF_EF),
  callListAdd: createReducer('CALL_LIST_ADD', DEFAULT_STATE_FF_EF),
  callListById: createReducer('CALL_LIST_BY_ID', DEFAULT_STATE_FF_EF),
  callListDelete: createReducer('CALL_LIST_DELETE', DEFAULT_STATE_FF_EF),

  //SIP calllist
  sipCallList: createReducer('SIP_CALL_LIST', DEFAULT_STATE_FF_EF),
  sipCallListAdd: createReducer('SIP_CALL_LIST_ADD', DEFAULT_STATE_FF_EF),
  sipCallListById: createReducer('SIP_CALL_LIST_BY_ID', DEFAULT_STATE_FF_EF),
  sipCallListDelete: createReducer('SIP_CALL_LIST_DELETE', DEFAULT_STATE_FF_EF),

  //Feed Back
  feedbackList: createReducer('FEEDBACK', DEFAULT_STATE_FF_EF),
  feedbackListID: createReducer('FEEDBACK_LIST_BY_ID', DEFAULT_STATE_FF_EF),
  feedbackComment: createReducer('FEEDBACK_COMMENT_BY_ID', DEFAULT_STATE_FF_EF),
  commentAdd: createReducer('COMMENT_ADD', DEFAULT_STATE_FF_EF),

  // Eteeru Dashboard
  dashboardUsersCount: createReducer('GET_USERS_COUNT', DEFAULT_STATE_FF_EF),
  dashboardPaymentsCount: createReducer(
    'GET_PAYMENTS_COUNT',
    DEFAULT_STATE_FF_EF
  ),
  dashboardTransactionCount: createReducer(
    'GET_TRANSACTIONS_COUNT',
    DEFAULT_STATE_FF_EF
  ),
    dashboardRechargeCount: createReducer('GET_RECHARGE_COUNT', DEFAULT_STATE_FF_EF),
  dashboardChatsCount: createReducer('GET_CHATS_COUNT', DEFAULT_STATE_FF_EF),
  dashboardUsers: createReducer('GET_DASHBOARD_USERS', DEFAULT_STATE_FF_EF),
  dashboardFeedbackCount: createReducer(
    'GET_DASHBOARD_FEEDBACK',
    DEFAULT_STATE_FF_EF
  ),
  dashboardDidNumbers:createReducer('GET_DID_NUMBERS_COUNT', DEFAULT_STATE_FF_EF),
  dashboardTopInstallation: createReducer(
    'GET_DASHBOARD_TOP_INSTALLATION',
    DEFAULT_STATE_FF_EF
  ),
  dashboardActiveUsers: createReducer(
    'GET_DASHBOARD_ACTIVE_USERS',
    DEFAULT_STATE_FF_EF
  ),
  dashboardTopGroupOrChannel: createReducer(
    'GET_DASHBOARD_TOP_GROUP_OR_CHANEL',
    DEFAULT_STATE_FF_EF
  ),
  dashboardTopChannel: createReducer(
    'GET_DASHBOARD_TOP_CHANEL',
    DEFAULT_STATE_FF_EF
  ),

  //kyc
  kycList: createReducer('KYC_LIST', DEFAULT_STATE_FF_EF),
  kycById: createReducer('KYC_BY_ID', DEFAULT_STATE_FF_EF),
  kycCommentAdd: createReducer('KYC_COMMENT_ADD', DEFAULT_STATE_FF_EF),
  kycCommentById: createReducer('KYC_COMMENT_BY_ID', DEFAULT_STATE_FF_EF),

  //Ad Management
  ad: createReducer('AD', DEFAULT_STATE_FF_EF),
  adAdd: createReducer('AD_ADD', DEFAULT_STATE_FF_EF),
  adById: createReducer('AD_BY_ID', DEFAULT_STATE_FF_EF),
  adDelete: createReducer('AD_DELETE', DEFAULT_STATE_FF_EF),

  //message
  message: createReducer('MESSAGE', DEFAULT_STATE_FF_EF),
  messageAdd: createReducer('MESSAGE_ADD', DEFAULT_STATE_FF_EF),
  messageId: createReducer('MESSAGE_ID', DEFAULT_STATE_FF_EF),
  messageDelete: createReducer('MESSAGE_DELETE', DEFAULT_STATE_FF_EF),

  //buisness Account
  business: createReducer('BUSINESS',DEFAULT_STATE_FF_EF),
  businessadd:createReducer('BUSINESS_ADD',DEFAULT_STATE_FF_EF),
  businessId:createReducer('BUSINESS_ID',DEFAULT_STATE_FF_EF),
  businessDelete:createReducer('BUSINESS_DELETE',DEFAULT_STATE_FF_EF),
  buisnessAccountDD:createReducer('GET_BUISNESS_ACCOUNT_DD',DEFAULT_STATE_FF_EF),
  buisnessGroupsDD:createReducer('GET_BUISNESS_GROUPS_DD',DEFAULT_STATE_FF_EF),

  //BROADCASTMESSAGES
  broadcastmessages: createReducer('BROADCAST_MESSAGE', DEFAULT_STATE_FF_EF),
  broadcastmessagesAdd: createReducer('BROADCAST_MESSAGE_ADD', DEFAULT_STATE_FF_EF),
  broadcastmessagesbyId: createReducer('BROADCAST_MESSAGE_BY_ID', DEFAULT_STATE_FF_EF),
  broadcastmessagesDelete: createReducer('BROADCAST_MESSAGE_DELETE', DEFAULT_STATE_FF_EF),

  //Broadscastmessage sent
  broadcastmessagesSend: createReducer('BROADCAST_MESSAGE_SEND', DEFAULT_STATE_FF_EF),
  //referral history
  referralHistory: createReducer('REFERRAL_HISTORY', DEFAULT_STATE_FF_EF),
  chatUserVersion: createReducer('USER_VERSION', DEFAULT_STATE_FF_EF),
  bulkUpload: createStaticReducer('BULK_UPLOAD', DEFAULT_STATE_FF_EF),
  updateBulkUpload:createReducer('UPDATE_BULK_UPLOAD', DEFAULT_STATE_FF_EF),

  //ussd banking
  ussdBanking: createReducer('USSD_BANKING', DEFAULT_STATE_FF_EF),
  ussdBankingAdd: createReducer('USSD_BANKING_ADD', DEFAULT_STATE_FF_EF),
  ussdBankingById: createReducer('USSD_BANKING_BY_ID', DEFAULT_STATE_FF_EF),
  ussdBankingDelete: createReducer('USSD_BANKING_DELETE', DEFAULT_STATE_FF_EF),

 //ringtones
 ringtones: createReducer('RINGTONES', DEFAULT_STATE_FF_EF),
 ringtonesAdd: createReducer('RINGTONES_ADD', DEFAULT_STATE_FF_EF),
 ringtonesById: createReducer('RINGTONES_BY_ID', DEFAULT_STATE_FF_EF),
 ringtonesDelete: createReducer('RINGTONES_DELETE', DEFAULT_STATE_FF_EF),
 ringtonesDD:createReducer('GET_RINGTONES_DD',DEFAULT_STATE_FF_EF),

 //ringtonemapping
  ringtonemapping: createReducer('RINGTONES_MAPPING', DEFAULT_STATE_FF_EF),
  ringtonemappingAdd: createReducer('RINGTONES_MAPPING_ADD', DEFAULT_STATE_FF_EF),
  ringtonemappingDelete: createReducer('RINGTONES_MAPPING_DELETE', DEFAULT_STATE_FF_EF),
 
  //redeempost
  referralRedeem: createReducer('REDEEM_REFERRAL_HISTORY', DEFAULT_STATE_FF_EF),

  //auditlogs
  auditLogs: createReducer('AUDIT_LOGS', DEFAULT_STATE_FF_EF),
  
});

export default reducers;
