import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, Grid } from 'antd';
import IntlMessage from '../util-components/IntlMessage';
import Icon from '../util-components/Icon';
import navigationConfig from 'configs/NavigationConfig';
import { connect } from 'react-redux';
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import utils from 'utils';
import { onMobileNavToggle } from 'redux/actions/Theme';
import DashboardIcon from '../../assets/svg/dashboard.svg';

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = '';
  if (key) {
    const arr = key.split('-');
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  const [rolesState, setRolesState] = useState(() => {
    const permission = JSON.parse(
      window.localStorage.getItem('audetails')
    )?.permissions;
    const roleModule = {};
    permission.forEach((value) => {
      if (value.module in roleModule) return;
      roleModule[value.module] = true;
    });
    return Object.keys(roleModule || {});
  });
  const [navMenu, setNavMenu] = useState(navigationConfig);

  const [subMenuValue, setSubMenuValue] = useState([]);
  const rootSubmenuKeys = [
    'manage_analytics',
    'manage_notifications',
    'manage_admins',
    'manage_message',
  ];

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => subMenuValue.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setSubMenuValue(keys);
    } else {
      setSubMenuValue(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  useEffect(() => {
    const value = {};
    const config = navigationConfig[0].submenu.map((val) => {
      const { submenu, role } = val;
      if (!rolesState.includes(role)) {
        return;
      }
      if (submenu.length > 0) {
        const validSubmenu = submenu.map((val) => {
          if (rolesState.includes(val.role)) {
            return val;
          }
        });
        let subMenuData = validSubmenu.filter((val) => Boolean(val));
        if (subMenuData.length === 0) {
          return;
        }
        val['submenu'] = subMenuData;
      }
      return val;
    });
    const finalConfig = navigationConfig;
    finalConfig[0]['submenu'] = config.filter((val) => Boolean(val));
    setNavMenu(finalConfig);
  }, [rolesState]);
  return (
    <Menu
      onOpenChange={onOpenChange}
      openKeys={subMenuValue}
      mode="inline"
      style={{ height: '100%', borderRight: 0, background: 'transparent' }}
      defaultSelectedKeys={[routeInfo?.key]}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? 'hide-group-title' : ''}
    >
      {navMenu.map((menu) =>
        menu.submenu.length > 0 ? (
          <Menu.ItemGroup
            key={menu.key}
            // title={setLocale(localization, menu.title)}
            title=""
            style={{ padding: 0 }}
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  icon={
                    subMenuFirst.icon ? (
                      <img alt="Submenu icon" src={subMenuFirst.icon} />
                    ) : null
                  }
                  key={subMenuFirst.key}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) =>
                    subMenuSecond.submenu.length > 0 ? (
                      <SubMenu
                        icon={
                          subMenuSecond.icon ? (
                            <img
                              alt="Submenu icon"
                              src={subMenuSecond.icon}
                              style={{ width: '40px' }}
                            />
                          ) : null
                        }
                        key={subMenuSecond.key}
                        title={subMenuSecond.title}
                      >
                        {subMenuSecond.submenu.map((subMenuThird) => {
                          return (
                            <Menu.Item key={subMenuThird.key}>
                              {subMenuThird.icon ? (
                                <Icon type={subMenuThird?.icon} />
                              ) : null}
                              <span>
                                {setLocale(localization, subMenuThird.title)}
                              </span>
                              <Link
                                onClick={() => closeMobileNav()}
                                to={subMenuThird.path}
                              />
                            </Menu.Item>
                          );
                        })}
                      </SubMenu>
                    ) : (
                      <Menu.Item
                        disabled={subMenuSecond?.disabled ?? false}
                        key={subMenuSecond.key}
                      >
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link
                          onClick={() => closeMobileNav()}
                          to={subMenuSecond.path}
                        />
                      </Menu.Item>
                    )
                  )}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <img alt={'icon'} src={subMenuFirst.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link
                    onClick={() => closeMobileNav()}
                    to={subMenuFirst.path}
                  />
                </Menu.Item>
              )
            )}
          </Menu.ItemGroup>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? (
              <Link onClick={() => closeMobileNav()} to={menu.path} />
            ) : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => {
                    return (
                      <Menu.Item key={subMenuSecond.key}>
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link to={subMenuSecond.path} />
                      </Menu.Item>
                    );
                  })}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
