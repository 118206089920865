import { SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig';

export const APP_NAME = 'STN'
export const API_BASE_URL = env.API_ENDPOINT_URL;

export const THEME_CONFIG = {
  navCollapsed: false,
  sideNavTheme: SIDE_NAV_DARK,
  locale: 'en',
  navType: NAV_TYPE_SIDE,
  topNavColor: '#3e82f7',
  headerNavColor: '#ffffff',
  mobileNav: false
};

export const UPLOAD_IMAGE_SIZE = 1; // in MB
