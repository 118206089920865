import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${match.url}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          path={`${match.url}/user-roles`}
          component={lazy(() => import(`./user-roles`))}
        />
        <Route
          path={`${match.url}/users`}
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={`${match.url}/users/:id`}
          component={lazy(() => import(`./profileInfo`))}
        />
        <Route
          path={`${match.url}/groups`}
          component={lazy(() => import(`./groups`))}
        />
        <Route
          path={`${match.url}/channels`}
          component={lazy(() => import(`./channels`))}
        />
          <Route
          path={`${match.url}/sip/didNumbers`}
          component={lazy(() => import(`./sip/didNumbers`))}
        />
            <Route
          path={`${match.url}/sip/didRequest`}
          component={lazy(() => import(`./sip/didRequest`))}
        />
           <Route
          path={`${match.url}/sip/didGroup`}
          component={lazy(() => import(`./didGroups`))}
        />
         <Route
          path={`${match.url}/sip/rate-groups`}
          component={lazy(() => import(`./sipRateGroups`))}
        />
           <Route
          path={`${match.url}/sip/operatorprefix`}
          component={lazy(() => import(`./sipOperatorPrefix`))}
        />
            <Route
          path={`${match.url}/sip/operator`}
          component={lazy(() => import(`./sipOperators`))}
        />
           <Route
          path={`${match.url}/sip/sip-rates`}
          component={lazy(() => import(`./sipRates`))}
        />
             <Route
          path={`${match.url}/sip/plan`}
          component={lazy(() => import(`./sipPlan`))}
        />
           <Route
          path={`${match.url}/coupon-code`}
          component={lazy(() => import(`./couponCode`))}
        />
          <Route
          path={`${match.url}/referralhistory`}
          component={lazy(() => import(`./referralHistory`))}
        />
  <Route
          path={`${match.url}/sip/callList`}
          component={lazy(() => import(`./sip/callList/index`))}
        />
           <Route
          path={`${match.url}/sip/cdr`}
          component={lazy(() => import(`./sip/CDR/index`))}
        />
         <Route
          path={`${match.url}/sip/servers`}
          component={lazy(() => import(`./sip/servers`))}
        />
          <Route
          path={`${match.url}/sip/didEntryAudit`}
          component={lazy(() => import(`./sip/didEntry`))}
        />
          <Route
          path={`${match.url}/sip/didRequesthistory`}
          component={lazy(() => import(`./sip/didHistory`))}
        />
          <Route
          path={`${match.url}/transaction`}
          component={lazy(() => import(`./transaction`))}
        />
    <Route
          path={`${match.url}/admin-users`}
          component={lazy(() => import(`./admin-users`))}
        />
        <Route
          path={`${match.url}/roles`}
          component={lazy(() => import(`./user-roles`))}
        />
        <Route
          path={`${match.url}/sip/callStats`}
          component={lazy(() => import(`./errorpage/error`))}
        />
        <Route
          path={`${match.url}/sip/callList`}
          component={lazy(() => import(`./sip/callList/index`))}
        />
         <Route
          path={`${match.url}/transaction/view/:id`}
          component={lazy(() => import(`./transaction/list/paymentDetails`))}
        />
         <Route
          path={`${match.url}/transaction/add`}
          component={lazy(() => import(`./transaction/add/index`))}
        />
        <Route
          path={`${match.url}/buisnessaccount/:id`}
          component={lazy(() => import(`./buisnessaccount/add`))}
        />
         <Route
          path={`${match.url}/broadcastmessages/add`}
          component={lazy(() => import(`./broadcastmessages/add`))}
        />
        <Route
          path={`${match.url}/broadcastmessages/view/:id`}
          component={lazy(() => import(`./broadcastmessages/view`))}
        />
        <Route
          path={`${match.url}/buisnessaccount`}
          component={lazy(() => import(`./buisnessaccount/list`))}
        />
        <Route
          path={`${match.url}/broadcastmessages`}
          component={lazy(() => import(`./broadcastmessages/list`))}
        />
        <Route
          path={`${match.url}/web-rtc`}
          component={lazy(() => import(`./webRtc/servers/index`))}
        />
         <Route
          path={`${match.url}/web-rtc/:id`}
          component={lazy(() => import(`./webRtc/servers/add`))}
        />
        <Route
          path={`${match.url}/support/feedback`}
          component={lazy(() => import(`./support/feedback/index`))}
        />{' '}
        <Route
          path={`${match.url}/support/feedback/:id`}
          component={lazy(() => import(`./support/feedback/add`))}
        />
         <Route
          path={`${match.url}/kyc`}
          component={lazy(() => import(`./kyc`))}
        />
        
         {/* <Route
          path={`${match.url}/support/feedback`}
          component={lazy(() => import(`./support/feedback/index`))}
        />
          <Route
          path={`${match.url}/support/feedback/:id`}
          component={lazy(() => import(`./support/feedback/add`))}
        /> */}
        <Route
          path={`${match.url}/manager`}
          component={lazy(() => import(`./manager/index`))}
        />
          <Route
          path={`${match.url}/calllist`}
          component={lazy(() => import(`./callList`))}
        />
        <Route
          path={`${match.url}/ad`}
          component={lazy(() => import(`./ad-management/index`))}
        />
        <Route
          path={`${match.url}/rule-engine`}
          component={lazy(() => import(`./ruleEngine/add`))}
        />
        <Route
          path={`${match.url}/analytics/call`}
          component={lazy(() => import(`./analyticsCall`))}
        />
        <Route
          path={`${match.url}/analytics/chats`}
          component={lazy(() => import(`./analyticsChat`))}
        />
        <Route
          path={`${match.url}/analytics/devices`}
          component={lazy(() => import(`./analyticsDevices`))}
        />
        <Route
          path={`${match.url}/analytics/recharge`}
          component={lazy(() => import(`./analyticsRecharge`))}
        />
        <Route
          path={`${match.url}/analytics/payment`}
          component={lazy(() => import(`./analyticsPayment`))}
        />
        <Route
          path={`${match.url}/setting/edit-profile`}
          component={lazy(() => import(`./pages/setting`))}
        />
        <Route
          path={`${match.url}/message`}
          component={lazy(() => import(`./message`))}
        />
         {/* <Route
          path={`${match.url}/transaction`}
          component={lazy(() => import(`./transactions`))}
        /> */}
         <Route
          path={`${match.url}/ringtones`}
          component={lazy(() => import(`./ringtones`))}
        />
        <Route
          path={`${match.url}/ringtonemapping`}
          component={lazy(() => import(`./ringtonemapping`))}
        />
        <Route
          path={`${match.url}/ussdbanking`}
          component={lazy(() => import(`./bankingPayment/index`))}
        />
         <Route
          path={`${match.url}/ussdbanking/:id`}
          component={lazy(() => import(`./webRtc/servers/add`))}
        />
           <Route
          path={`${match.url}/KPI`}
          component={lazy(() => import(`./errorpage/error`))}

        />
        <Route
          path={`${match.url}/auditlogs`}
          component={lazy(() => import(`./auditlogs`))}
        />
         <Route
          path={`${match.url}/broadcastgroups`}
          component={lazy(() => import(`./broadcastgroups`))}

/>
        <Redirect from={match.url} to={`${match.url}/dashboard`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
