import { DashboardOutlined } from '@ant-design/icons';

import DashboardIcon from '../assets/svg/sidebar-icons/Dashboard.svg';
import ChannelIcon from '../assets/svg/sidebar-icons/channel.svg';
import GroupIcon from '../assets/svg/sidebar-icons/group.svg';
import UserIcon from '../assets/svg/sidebar-icons/Contacts.svg';
import WebRtcIcon from '../assets/svg/sidebar-icons/Admin settings.svg';
import ManageAdminIcon from '../assets/svg/manageadmin.svg';
import MonetizationIcon from '../assets/svg/monetization.svg';
import SipIcon from '../assets/svg/SIP.svg';
import NotificationIcon from '../assets/svg/notification.svg';
import AuditIcon from '../assets/svg/audit.svg';
import ReportIcon from '../assets/svg/sidebar-icons/Reports.svg';
import Call from '../assets/svg/sidebar-icons/Call.svg';
import CallList from '../assets/svg/sidebar-icons/callList.svg';
import Admanagmnent from '../assets/svg/sidebar-icons/Admanagment.svg';
import Notification from '../assets/svg/sidebar-icons/Notification.svg';
import Feedback from '../assets/svg/sidebar-icons/Feedback.svg';
import KPI from '../assets/svg/sidebar-icons/KPI.svg';
import ManageAdmin from '../assets/svg/sidebar-icons/manageAdmin.svg';
import Message from '../assets/svg/sidebar-icons/Message.svg';

const dashBoardNavTree = [
  {
    key: 'dashboard',
    path: '/app/dashboard',
    title: 'dashboard',
    role: 'Dashboard',
    icon: DashboardIcon,
    breadcrumb: false,
    submenu: []
  }
];

const users = [
  {
    key: 'users',
    path: '/app/users',
    title: 'users',
    role: 'Users',
    breadcrumb: false,
    icon: UserIcon,
    submenu: []
  }
];

const groups = [
  {
    key: 'groups',
    path: '/app/groups',
    title: 'groups',
    role: 'Groups',
    icon: GroupIcon,
    breadcrumb: false,
    submenu: []
  }
];

const channels = [
  {
    key: 'channels',
    path: '/app/channels',
    title: 'channels',
    role: 'Channels',
    breadcrumb: false,
    icon: ChannelIcon,
    submenu: []
  }
];
// banking
const banking = [
  {
    key: 'logs',
    path: '/app/auditlogs',
    title: ' Audit Logs',
    role: 'WebRTC Servers',
    icon: WebRtcIcon,
    breadcrumb: false,
    submenu: []
  }
];

const appToAppCallNavTree = [
  {
    key: 'appToAppCall',
    path: '/app/web-rtc',
    title: 'WebRTC Servers',
    role: 'WebRTC Servers',
    icon: WebRtcIcon,
    breadcrumb: false,
    submenu: []
  }
];

const callList = [
  {
    key: 'callList',
    path: '/app/callList',
    title: 'WebRTC Call List',
    role: 'WebRTC Call List',
    icon: CallList,

    breadcrumb: false,
    submenu: []
  }
];
const manageRuleEngine = [
  {
    key: 'ruleengine',
    path: '/app/rule-engine',
    title: 'Rule Engine',
    role: 'Rule Engine',
    breadcrumb: false,
    icon: WebRtcIcon,
    submenu: []
  }
];

const manageAnalytics = [
  {
    key: 'manage_analytics',
    title: 'Analytics',
    role: 'WebRTC Call List',
    icon: ReportIcon,
    breadcrumb: false,
    submenu: [
      {
        key: 'calls',
        path: '/app/analytics/call',
        title: 'Calls',
        role: 'Calls',
        // icon: Call,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'chats',
        path: '/app/analytics/chats',
        title: 'Chat',
        role: 'Chats',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'devices',
        path: '/app/analytics/devices',
        title: 'Device',
        role: 'Devices',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'recharge',
        path: '/app/analytics/recharge',
        title: 'Recharge',
        role: 'Recharge',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'payment',
        path: '/app/analytics/payment',
        title: 'Payments',
        role: 'Payment',
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];
const admanagement = [
  {
    key: 'admanagement',
    path: '/app/ad',
    title: 'Ad Management',
    role: 'Ad Management',
    breadcrumb: false,
    icon: Admanagmnent,
    submenu: []
  }
];

//feedback

const mangefeedback = [
  {
    key: 'adminUsers',
    path: '/app/support/feedback',
    title: 'managefeedback',
    icon: Feedback,

    role: 'Feedback',
    breadcrumb: false,
    submenu: []
  }
];
const managenotification = [
  {
    key: 'manage_notifications',
    path: '/app/webRtc/callList',
    title: 'Broadcasting',
    role: 'WebRTC Call List',
    icon: Notification,
    breadcrumb: false,
    submenu: [
      {
        key: 'broadcast',
        path: '/app/buisnessaccount',
        title: 'Business Account',
        role: 'Broadcast',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'broadcastgroups',
        path: '/app/broadcastgroups',
        title: 'Broadcast Groups',
        role: 'Broadcast',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'broadcastmessages',
        path: '/app/broadcastmessages',
        title: 'Broadcast Messages',
        role: 'Broadcast',
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];
const manageKPI = [
  {
    key: 'KPI',
    path: '/app/KPI',
    title: 'KPI',
    role: 'KPI',
    breadcrumb: false,
    icon: KPI,
    submenu: []
  }
];

const wallet = [
  {
    key: 'wallet',
    title: 'Wallet',
    role: 'Payment',
    icon: ReportIcon,
    breadcrumb: false,
    submenu: [
      {
        key: 'transaction',
        path: '/app/transaction',
        title: 'Transaction',
        role: 'Payment',
        // icon: Call,
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];
// Route Management

// const routeManagementNavTree = [
//   {
//     key: 'wallet',
//     title: 'Wallet',
//     role: 'SIP Servers',
//     icon: SipIcon,
//     breadcrumb: false,
//     submenu: [
//       {
//         key: 'transactions',
//         path: '/app/transactions',
//         title: 'Transactions',
//         role: 'SIP Servers',
//         breadcrumb: false,
//         submenu: []
//       },
//       // {
//   key: 'didRequest',
//   path: '/app/sip/didRequest',
//   title: 'DID Request',
//   role: 'DID Request',
//   breadcrumb: false,
//   submenu: []
// },
// {
//   key: 'didGroup',
//   path: '/app/sip/didGroup',
//   title: 'DID Group',
//   role: 'DID Groups',
//   breadcrumb: false,
//   submenu: []
// }
//     ]
//   }
// ];

// Rates and Packages
const ratesAndPackagesNavTree = [
  {
    key: 'ratesAndPackages',
    path: '/app/sipConfigs',
    title: 'Rates and Packages',
    role: 'SIP Servers',
    icon: SipIcon,
    breadcrumb: false,
    submenu: [
      {
        key: 'sipRateGroups',
        path: '/app/sip/rate-groups',
        title: 'Rate Groups',
        role: 'SIP Rate Groups',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'sipOperator',
        path: '/app/sip/operator',
        title: 'Operators',
        role: 'SIP Operator',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'sipOperatorPrefix',
        path: '/app/sip/operatorprefix',
        title: 'Operators Prefix',
        role: 'SIP Operator',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'sipRates',
        path: '/app/sip/sip-rates',
        title: 'Rates',
        role: 'SIP Rates',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'sipPlan',
        path: '/app/sip/plan',
        title: 'Package Plan',
        role: 'SIP Plan',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'couponCodes',
        path: '/app/coupon-code',
        title: 'Coupon Codes',
        role: 'Coupon Codes',
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];
const systemsAndServiceNavTree = [
  {
    key: 'systemAndServices',
    path: '/app/web-rtc',
    title: 'Systems and Service',
    role: 'WebRTC Servers',
    icon: WebRtcIcon,
    breadcrumb: false,
    submenu: [
      // {
      //   key: 'servers',
      //   path: '/app/webRtc/servers',
      //   title: 'Manage Admins',
      //   role: 'WebRTC Servers',
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: 'adminUsers',
      //       path: '/app/admin-users',
      //       title: 'adminUsers',
      //       role: 'Admin Users',
      //       breadcrumb: false,
      //       submenu: []
      //     },
      //     {
      //       key: 'roles',
      //       path: '/app/roles',
      //       title: 'roles',
      //       role: 'Roles',
      //       breadcrumb: false,
      //       submenu: []
      //     }
      //   ]
      // },
      {
        key: 'sipServer',
        path: '/app/sip/servers',
        title: 'SIP Servers',
        role: 'SIP Servers',
        breadcrumb: false,
        submenu: []

        // {
        //   key: 'webrtcServer',
        //   path: '/app/webRtc/servers',
        //   title: 'WebRTC',
        //   role: 'WebRTC Servers',
        //   breadcrumb: false,
        //   submenu: []
        // }
      }
      // {
      //   key: 'paymentGateway',
      //   path: '/app/webRtc/callList',
      //   title: 'Payment Gateway config notifications',
      //   role: 'WebRTC Call List',
      //   breadcrumb: false,
      //   submenu: [
      //     {
      //       key: 'emailSettings',
      //       path: '/app/emailSettings',
      //       title: 'emailSettings',
      //       role: 'Email Settings',
      //       breadcrumb: false,
      //       submenu: []
      //     },
      //     {
      //       key: 'smsSettings',
      //       path: '/app/smsSettings',
      //       title: 'smsSettings',
      //       role: 'SMS Settings',
      //       breadcrumb: false,
      //       submenu: []
      //     },
      //     {
      //       key: 'broadcast',
      //       path: '/app/broadcast',
      //       title: 'Admin Broadcast',
      //       role: 'Broadcast',
      //       breadcrumb: false,
      //       submenu: []
      //     }
      //   ]
      // },
      // {
      //   key: 'Lawful Intercept',
      //   path: '/app/webRtc/callList',
      //   title: 'Lawful Intercept',
      //   role: 'WebRTC Call List',
      //   breadcrumb: false,
      //   disabled: true,
      //   submenu: []
      // }
    ]
  }
];

// Billing -Accounting
const billingAccountingNavTree = [
  {
    key: 'monetization',
    path: '/app/monetization',
    title: 'Billing and Accounting',
    role: 'SIP Plan',
    icon: MonetizationIcon,
    breadcrumb: false,
    submenu: [
      {
        key: 'transactions',
        path: '/app/transaction',
        title: 'transactions',
        role: 'CDR',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'sip_calllist',
        path: '/app/sip/callList',
        title: 'SIP Call List',
        role: 'CDR',
        breadcrumb: false,
        submenu: []
      }, 
      {
        key: 'cdr',
        path: '/app/sip/cdr',
        title: 'CDR',
        role: 'CDR',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'referral_plan',
        path: '/app/referralhistory',
        title: 'Referral History',
        role: 'CDR',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'banking',
        path: '/app/ussdbanking',
        title: 'USSD Banking',
        role: 'CDR',
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];

// Reports
const reportsNavTree = [
  {
    key: 'kyc',
    path: '/app/kyc',
    title: 'KYC',
    role: 'KYC Logs',
    icon: AuditIcon,
    breadcrumb: false,
    submenu: []
     
  }
];
// const appToAppCallNavTree = [
//   {
//     key: 'appToAppCall',
//     path: '/app/web-rtc',
//     title: 'WebRTC Servers',
//     role: 'WebRTC Servers',
//     icon: WebRtcIcon,
//     breadcrumb: false,
//     submenu: []
//   }
// ];
// Systems and Service
const manageAdmins = [
  {
    key: 'manage_admins',
    // path: '/app/webRtc/servers',
    title: 'Manage Admins',
    role: 'User Audit Logs',
    icon: ManageAdmin,
    breadcrumb: false,
    submenu: [
      {
        key: 'adminUsers',
        path: '/app/admin-users',
        title: 'adminUsers',
        role: 'Admin Users',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'roles',
        path: '/app/roles',
        title: 'roles',
        role: 'Roles',
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];

const manageAdminsNavTree = [
  {
    key: 'manageAdmins',
    path: '/app/manageAdmins',
    title: 'manageAdmins',
    role: 'Admin Users',
    icon: ManageAdminIcon,
    breadcrumb: false,
    submenu: [
      {
        key: 'adminUsers',
        path: '/app/admin-users',
        title: 'adminUsers',
        role: 'Admin Users',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'roles',
        path: '/app/roles',
        title: 'roles',
        role: 'Roles',
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];
const routeManagementNavTree = [
  {
    key: 'routeManagement',
    path: '/app/sipConfigs',
    title: 'Route Management',
    role: 'SIP Servers',
    icon: SipIcon,
    breadcrumb: false,
    submenu: [
      {
        key: 'didGroup',
        path: '/app/sip/didGroup',
        title: 'DID Group',
        role: 'DID Groups',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'didNumbers',
        path: '/app/sip/didNumbers',
        title: 'didNumbers',
        role: 'DID Numbers',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'didRequest',
        path: '/app/sip/didRequest',
        title: 'DID Allocation',
        role: 'DID Request',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'didRequestHistory',
        path: '/app/sip/didRequesthistory',
        title: 'DID Number History',
        role: 'DID Request',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'didEntryAudit',
        path: '/app/sip/didEntryAudit',
        title: 'DID Entry Audit',
        role: 'DID Request',
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];

const monetizationConfigNavTree = [
  {
    key: 'monetization',
    path: '/app/monetization',
    title: 'monetization',
    role: 'SIP Plan',
    icon: MonetizationIcon,
    breadcrumb: false,
    submenu: [
      {
        key: 'sipPlan',
        path: '/app/sip/plan',
        title: 'SIP Plan',
        role: 'SIP Plan',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'couponCodes',
        path: '/app/coupon-code',
        title: 'couponCodes',
        role: 'Coupon Codes',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'transactions',
        path: '/app/transaction',
        title: 'transactions',
        role: 'Transcations',
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];

const notification = [
  {
    key: 'notification',
    path: '/app/notification',
    title: 'notification',
    role: 'Email Settings',
    icon: NotificationIcon,
    breadcrumb: false,
    submenu: [
      {
        key: 'emailSettings',
        path: '/app/emailSettings',
        title: 'emailSettings',
        role: 'Email Settings',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'smsSettings',
        path: '/app/smsSettings',
        title: 'smsSettings',
        role: 'SMS Settings',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'broadcast',
        path: '/app/broadcast',
        title: 'Admin Broadcast',
        role: 'Broadcast',
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];

const managemessage = [
  {
    key: 'manage_message',

    title: 'Chat settings',
    role: 'Users',
    breadcrumb: false,
    icon: Message,
    submenu: [
      {
        key: 'busymessage',
        path: '/app/message',
        title: 'Auto Reply',
        role: 'Automatic Replies',
        breadcrumb: false,
        submenu: []
      }
      // {
      //   key: 'buisnessAccount',
      //   path: '/app/buisnessaccount',
      //   title: 'Buisness Account',
      //   role: 'Admin Broadcast',
      //   breadcrumb: false,
      //   submenu: []
      // }
    ]
  }
];

const manageCalls = [
  {
    key: 'manage_calls',
    title: 'Call settings',
    role: 'Users',
    breadcrumb: false,
    icon: Message,
    submenu: [
      {
        key: 'ringtones',
        path: '/app/ringtones',
        title: 'Ringtones',
        role: 'Coupon Codes',
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'ringtonemapping',
        path: '/app/ringtonemapping',
        title: 'Ringtone Mapping',
        role: 'Coupon Codes',
        breadcrumb: false,
        submenu: []
      }
    ]
  }
];
const navigationConfig = [
  {
    // default theme structure
    key: 'dashboard',
    path: '/app/dashboard',
    title: 'dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [
      ...dashBoardNavTree,
      ...users,
      ...groups,
      ...channels,
      ...routeManagementNavTree,
      ...ratesAndPackagesNavTree,
      ...billingAccountingNavTree,

      ...callList,
      // ...manageRuleEngine,
      // ...manageAnalytics,
      // ...admanagement,
      ...managemessage,
      ...manageCalls,
      ...managenotification,
      ...mangefeedback,
      ...systemsAndServiceNavTree,
      ...reportsNavTree,
      // ...manageKPI,
      ...manageAdmins,
      // ...wallet
      ...appToAppCallNavTree,
      ...banking
    ]
  }
];

export default navigationConfig;
